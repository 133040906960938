@keyframes slideFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-20%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.HomeTitleBox {
  height: 290px;
  padding-top: 7.125rem;
  word-break: keep-all;

  margin: 0 3.5rem;
  text-align: center;

  @media all and (min-width: 768px) {
    margin: 0 12rem;
    text-align: left;
  }

  h1 {
    font-size: 18px;

    @media all and (min-width: 768px) {
      font-size: 36px;
    }

    font-weight: normal;
    opacity: 0;
    animation: slideFromLeft 1.5s forwards;
  }

  div[class^='home-title-box-pc'] {
    display: none;

    @media all and (min-width: 768px) {
      display: block;
      font-size: 46px;
      font-weight: bold;
      opacity: 0;
      animation: slideFromLeft 1.5s 1s forwards;
    }
  }

  div[class^='home-title-box-mobile'] {
    font-size: 28px;
    font-weight: bold;
    opacity: 0;
    line-height: 42px;
    animation: slideFromLeft 1.5s 1s forwards;

    @media all and (min-width: 768px) {
      display: none;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.HomeSection2 {
  background: linear-gradient(to bottom, #292d3c 40%, #12131a 100%);
  margin-top: -2rem;
  text-align: center;
  padding: 8rem 2.5rem 60rem 2.5rem;
  word-break: keep-all;

  h1 {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 0.25rem;

    @media all and (min-width: 768px) {
      font-size: 64px;
      margin-bottom: 3rem;
    }
  }

  div[class^='home-description-pc'] {
    display: none;

    @media all and (min-width: 768px) {
      display: block;
    }

    p {
      font-weight: 300;
      margin: 0;
      animation: fadeIn 1s forwards;

      font-size: 30px;
      line-height: 55px;
    }
  }

  div[class^='home-description-mobile'] {
    @media all and (min-width: 768px) {
      display: none;
    }

    p {
      font-weight: 300;
      margin: 0;
      animation: fadeIn 1s forwards;

      font-size: 13px;
      line-height: 28px;
    }
  }
}

.HomeSection3 {
  margin-top: -53rem;
  padding: 0 0.5rem;

  @media all and (min-width: 768px) {
    margin-top: -30rem;
  }

  display: flex;
  justify-content: center;

  img {
    margin-top: 1.5rem;
    width: auto;

    height: 520px;

    @media all and (min-width: 480px) {
      height: 750px;
    }

    @media all and (min-width: 768px) {
      height: 920px;
    }

    @media all and (min-width: 1200px) {
      height: 1120px;
    }
  }
}

.RocketDescription {
  h2 {
    font-size: 14px;
    font-weight: normal;
    color: rgba(256, 256, 256, 0.5);
    margin-bottom: 0.4rem;

    @media all and (min-width: 768px) {
      font-size: 30px;
    }
  }

  h1 {
    font-size: 26px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 1rem;

    @media all and (min-width: 768px) {
      font-size: 58px;
      margin-bottom: 3rem;
    }
  }

  p {
    font-size: 10px;
    font-weight: normal;
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 18px;

    @media all and (min-width: 480px) {
      font-size: 13px;
      margin-bottom: 1.3rem;
      line-height: 26px;
    }

    @media all and (min-width: 768px) {
      font-size: 20px;
      margin-bottom: 1.8rem;
      line-height: 40px;
    }

    @media all and (min-width: 1200px) {
      font-size: 22px;
      margin-bottom: 2rem;
      line-height: 40px;
    }
  }
}

.HectorDescription {
  text-align: right;
  margin-left: 0.9rem;

  @media all and (min-width: 480px) {
    margin-left: 2rem;
  }

  @media all and (min-width: 768px) {
    margin-left: 3rem;
  }

  @media all and (min-width: 1200px) {
    text-align: left;
    margin-left: 10rem;
  }

  @media all and (min-width: 1200px) {
    margin-left: 15rem;
  }

  @extend .RocketDescription;
}

.HomeSection4 {
  text-align: center;
  margin-top: 3rem;

  @media all and (min-width: 768px) {
    font-size: 50px;
    margin-top: 14rem;
  }

  h1 {
    font-size: 11px;
    font-weight: 600;
    word-break: keep-all;

    @media all and (min-width: 768px) {
      font-size: 50px;
    }
  }

  video {
    width: 72%;
    height: auto;
    margin-top: 0.5rem;

    @media all and (min-width: 768px) {
      margin-top: 0.75rem;
      width: 63%;
    }
  }

  p {
    font-size: 10px;
    font-weight: normal;
    line-height: 18px;
    margin-top: 0.75rem;
    margin-bottom: 0.2rem;
    word-break: keep-all;

    @media all and (min-width: 768px) {
      font-size: 30px;
      line-height: 62px;
      margin-top: 2.5rem;
      margin-bottom: 1rem;
    }
  }
}

.TotalWarDescription {
  text-align: left;
  margin-right: 0.9rem;

  @media all and (min-width: 480px) {
    margin-right: 2rem;
  }

  @media all and (min-width: 768px) {
    margin-right: 2rem;
  }

  @media all and (min-width: 1200px) {
    margin-right: 2rem;
  }

  @media all and (min-width: 1400px) {
    margin-right: 15rem;
  }

  @extend .RocketDescription;
}

.HomeSection5 {
  margin-top: 4.5rem;
  display: flex;
  justify-content: center;
  // margin-bottom: 8rem;
  padding: 0 0.5rem;

  @media all and (min-width: 768px) {
    margin-top: 15rem;
  }

  img {
    margin-top: 2.5rem;
    width: auto;
    height: 421px;

    @media all and (min-width: 480px) {
      // margin-bottom: 10rem;
      // margin-top: 15rem;
      height: 660px;
    }

    @media all and (min-width: 768px) {
      // margin-top: 20rem;
      height: 920px;
    }

    @media all and (min-width: 1200px) {
      // margin-top: 18rem;
      // margin-bottom: 28rem;
      height: 1180px;
    }
  }
}


.OrcaDescription {
  align-self: center;
  text-align: left;
  margin-left: 0.9rem;

  @media all and (min-width: 480px) {
    margin-left: 2rem;
  }

  @media all and (min-width: 768px) {
    margin-left: 2rem;
  }

  @media all and (min-width: 1200px) {
    margin-left: 2rem;
  }

  @media all and (min-width: 1400px) {
    margin-left: 15rem;
  }

  @extend .RocketDescription;
}

.HomeSection6 {
  display: flex;
  justify-content: center;
  margin-bottom: 8rem;
  padding: 0 0.5rem;

  @media all and (min-width: 768px) {
    margin-top: 15rem;
  }

  img {
    margin-top: 2.5rem;
    width: auto;
    height: 421px;

    @media all and (min-width: 480px) {
      margin-bottom: 5rem;
      // margin-top: 15rem;
      height: 660px;
    }

    @media all and (min-width: 768px) {
      // margin-top: 20rem;
      height: 920px;
    }

    @media all and (min-width: 1200px) {
      // margin-top: 18rem;
      margin-bottom: 8rem;
      height: 1180px;
    }
  }
}
