@keyframes slideFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-20%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.JoinUsTitleBox {
  height: 290px;
  padding-top: 7.125rem;
  padding-bottom: 20rem;
  word-break: keep-all;

  text-align: center;

  @media all and (min-width: 768px) {
    text-align: left;
  }

  h1 {
    font-size: 18px;
    font-weight: normal;
    opacity: 0;
    animation: slideFromLeft 1.5s forwards;

    @media all and (min-width: 768px) {
      font-size: 36px;
    }
  }

  div {
    font-size: 28px;
    font-weight: bold;
    opacity: 0;
    animation: slideFromLeft 1.5s 1s forwards;

    @media all and (min-width: 768px) {
      font-size: 46px;
    }
  }
}

.JoinUsSection1 {
  position: relative;
  overflow: hidden;
  font-weight: bold;
  padding: 0 2rem;
  word-break: keep-all;

  @media all and (min-width: 768px) {
    padding: 0 12rem;
  }

  & > h1 {
    display: none;
    font-size: 57px;

    @media all and (min-width: 768px) {
      display: block;
    }
  }

  & > h2 {
    font-size: 23px;
    text-align: center;
    line-height: 35px;

    @media all and (min-width: 768px) {
      display: none;
    }
  }
}

/* 어비셜 로켓 로고 클래스 */
.JoinUsSection1:before {
  content: '';
  position: absolute;
  width: 160%;
  height: 160%;
  z-index: -1;
  top: -25%;
  left: -35%;
  background-image: url('../assets/Abyssal_Logo_Rocket.png');
  background-repeat: no-repeat;
  background-position: right 10% center;
  transform: rotate(-10deg);
  opacity: 0.24;

  background-size: 50%;

  @media all and (min-width: 768px) {
    background-size: 30%;
  }
}

.JoinUsSection2 {
  padding: 10rem 2rem;

  @media all and (min-width: 768px) {
    padding: 16rem 12rem;
  }

  p {
    font-size: 13px;
    font-weight: 300;
    line-height: 28px;
    text-align: center;
    word-break: keep-all;

    @media all and (min-width: 768px) {
      font-size: 30px;
      line-height: 55px;
    }
  }
}

.JoinUsSection3 {
  padding: 0 2rem;

  @media all and (min-width: 768px) {
    padding: 0 5rem;
  }

  @media all and (min-width: 1200px) {
    padding: 0 12rem;
  }

  h2 {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;

    @media all and (min-width: 768px) {
      font-size: 32px;
      line-height: 38px;
    }
  }
}

.JoinUsHistoryBox {
  padding-bottom: 2rem;

  @media all and (min-width: 768px) {
    padding-bottom: 7rem;
  }

  div {
    font-size: 10px;
    padding-top: 1rem;
    font-weight: 300;
    color: rgba(256, 256, 256, 0.6);

    @media all and (min-width: 768px) {
      font-size: 24px;
      padding-top: 0;
    }
  }

  h1 {
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;

    margin: 0;
    word-break: keep-all;

    @media all and (min-width: 768px) {
      font-size: 42px;
      line-height: 73px;
    }
  }
}

.JoinUsSection4 {
  padding: 0 2rem;
  padding-top: 6rem;
  margin-bottom: 12rem;

  @media all and (min-width: 768px) {
    padding: 0 5rem;
  }

  @media all and (min-width: 1200px) {
    display: flex;
    align-items: center;
    padding: 0 12rem;
  }

  h2 {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;

    @media all and (min-width: 768px) {
      font-size: 32px;
      line-height: 38px;
    }
  }
}

.JoinUsForm {
  background-color: #191b25;
  height: 500px;
  border-radius: 2.5rem;
  margin-top: 2.4rem;

  @media all and (min-width: 1200px) {
    height: 70vh;
  }
}

.JoinUsDescription {
  word-break: keep-all;

  padding: 2rem 1.5rem;
  @media all and (min-width: 768px) {
    padding: 0;
    padding-top: 2rem;
  }

  @media all and (min-width: 1200px) {
    padding-left: 3rem;
  }

  @media all and (min-width: 1400px) {
    padding-left: 10rem;
  }

  h2 {
    padding-top: 1rem;

    @media all and (min-width: 768px) {
      padding-top: 2.5rem;
      padding-left: 2.5rem;
    }
  }

  ul {
    margin: 0;
    padding-left: 1rem;

    @media all and (min-width: 768px) {
      padding-left: 4rem;
    }
  }

  li {
    font-size: 12px;
    line-height: 20px;
    font-weight: normal;
    padding-bottom: 0.25rem;

    @media all and (min-width: 768px) {
      font-size: 22px;
      line-height: 38px;
    }
  }
}
