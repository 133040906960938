.Footer {
  position: relative;
  background-color: #1a1a24;
  height: 251px;
  color: #7b828c;

  @media all and (min-width: 768px) {
    height: 552px;
  }

  & > div:first-child {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}

.FooterLogoBox {
  text-align: center;
  padding-bottom: 2.5rem;
}

.FooterLogo {
  width: 94px;
  height: 91px;
  margin: 0 1.5rem;
  display: block;

  @media all and (min-width: 768px) {
    width: 187px;
    height: 181px;
    margin: 0 6rem;
  }
}

.FooterContactUs {
  margin: 0 1.5rem;
  text-align: center;
  padding-bottom: 4rem;

  @media all and (min-width: 768px) {
    margin: 0 6rem;
  }

  h3 {
    font-size: 10px;
    font-weight: bold;
    padding-bottom: 0.25rem;

    @media all and (min-width: 768px) {
      font-size: 24px;
      padding-bottom: 0.6rem;
    }
  }
}

.FooterContactTime {
  padding-bottom: 0.75rem;
  font-size: 10px;

  @media all and (min-width: 768px) {
    padding-bottom: 1.2rem;
    font-size: 22px;
  }

  span:first-child {
    color: #707070;
    font-weight: 600;
  }

  span:nth-child(2) {
    font-size: 10px;
    margin-left: 10px;
    font-weight: 600;

    @media all and (min-width: 768px) {
      font-size: 22px;
    }
  }
}

.FooterSlogan {
  font-size: 10px;
  font-weight: bold;
  line-height: 18px;

  @media all and (min-width: 768px) {
    font-size: 24px;
    line-height: 40px;
  }
}

.FooterCopyright {
  position: absolute;
  left: 13px;
  bottom: 23px;

  span {
    font-size: 8px;
    line-height: 16px;

    font-weight: 600;
    padding: 0 10px;

    @media all and (min-width: 768px) {
      font-size: 18px;
      line-height: 32px;
    }
  }
}

.FooterContactEmail {
  padding-top: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media all and (min-width: 768px) {
    padding-top: 0.8rem;
  }

  span {
    font-weight: 600;
    font-size: 10px;

    @media all and (min-width: 768px) {
      font-size: 22px;
    }
  }

  img {
    width: 12px;
    height: 9px;
    margin-right: 1rem;

    @media all and (min-width: 768px) {
      width: 24px;
      height: 19px;
      margin-right: 1.4rem;
    }
  }
}
