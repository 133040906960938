.NavbarStickToTop {
  position: sticky;
  top: 0;
  z-index: 99999;
}

.Navbar {
  background-color: #12131a;
  height: 76px;
  position: relative;

  & nav {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.75rem;

    @media all and (min-width: 768px) {
      padding: 0 1.5rem;
    }
  }
}

.NavMenu {
  display: none;

  @media all and (min-width: 768px) {
    display: block;
  }

  padding-bottom: 4px;

  a {
    color: #707070;
    transition: color 0.3s;
    text-decoration: none;
    font-weight: bold;
    font-size: 12px;
    padding: 8px 15px;
    margin: 0 5px;

    &:hover {
      color: white;
      transition: color 0.3s;
    }
  }
}

.NavActive {
  color: white !important;
}

.NavLogo {
  padding-left: 20px;
  padding-top: 4px;
  width: 139px;
  height: 32px;
}

.NavButtonToggle {
  display: block;
  cursor: pointer;

  @media all and (min-width: 768px) {
    display: none;
  }

  background: none;
  border: none;
  margin-right: 0.8rem;

  img {
    width: 65%;
    height: 65%;
    padding: 0.5rem;
  }
}

@keyframes unfold {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

@keyframes fold {
  0% {
    opacity: 1;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform: scaleY(0);
  }
}

.NavMenuMobile {
  transform-origin: top;
  background-color: rgba(18, 19, 26, 0.8);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  animation: unfold 0.2s forwards;

  padding: 0.5rem 2rem;
  opacity: 1;

  a {
    display: block;
    color: #707070;
    transition: color 0.3s;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    padding: 8px 0;

    &:hover {
      color: white;
      transition: color 0.3s;
    }
  }
}

.NavMenuMobileClose {
  animation: fold 0.2s forwards;

  @extend .NavMenuMobile;
}