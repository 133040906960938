@keyframes slideFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-20%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.SponsorTitleBox {
  height: 290px;
  padding-top: 7.125rem;
  padding-bottom: 20rem;
  word-break: keep-all;

  text-align: center;

  @media all and (min-width: 768px) {
    text-align: left;
  }

  h1 {
    font-size: 18px;
    font-weight: normal;
    opacity: 0;
    animation: slideFromLeft 1.5s forwards;

    @media all and (min-width: 768px) {
      font-size: 36px;
    }
  }

  div {
    font-size: 28px;
    font-weight: bold;
    opacity: 0;
    animation: slideFromLeft 1.5s 1s forwards;

    @media all and (min-width: 768px) {
      font-size: 46px;
    }
  }
}

.SponsorSection1 {
  position: relative;
  overflow: hidden;
  font-weight: bold;
  padding: 0 2rem;
  word-break: keep-all;

  @media all and (min-width: 768px) {
    padding: 0 12rem;
  }

  & > h1 {
    display: none;
    font-size: 57px;

    @media all and (min-width: 768px) {
      display: block;
    }
  }

  & > h2 {
    font-size: 23px;
    text-align: center;
    line-height: 35px;

    @media all and (min-width: 768px) {
      display: none;
    }
  }
}

/* 어비셜 로켓 로고 클래스 */
.SponsorSection1:before {
  content: '';
  position: absolute;
  width: 160%;
  height: 160%;
  z-index: -1;
  top: -25%;
  left: -35%;
  background-image: url('../assets/Abyssal_Logo_Rocket.png');
  background-repeat: no-repeat;
  background-position: right 10% center;
  transform: rotate(-10deg);
  opacity: 0.24;

  background-size: 50%;

  @media all and (min-width: 768px) {
    background-size: 30%;
  }
}

.SponsorSection2 {
  padding: 10rem 2rem;

  @media all and (min-width: 768px) {
    padding: 16rem 5rem;
  }

  @media all and (min-width: 1200px) {
    padding: 16rem 12rem;
  }

  h1 {
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
    margin: 0;
    text-align: center;

    @media all and (min-width: 768px) {
      font-size: 64px;
      line-height: 75px;
    }
  }

  p {
    font-size: 13px;
    font-weight: 300;
    line-height: 28px;
    text-align: center;
    word-break: keep-all;

    @media all and (min-width: 768px) {
      font-size: 30px;
      line-height: 55px;
    }
  }
}

.SponsorSmallHeader {
  display: flex;
  align-items: end;

  h2 {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    margin: 0;
    white-space: nowrap;

    @media all and (min-width: 768px) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  span {
    font-size: 10px;
    font-weight: 500;
    padding-left: 2rem;

    @media all and (min-width: 768px) {
      font-size: 24px;
      font-weight: 500;
      padding-left: 2rem;
    }
  }
}

.SponsorSection3,
.SponsorSection4,
.SponsorSection5 {
  padding: 0 2rem;
  padding-bottom: 5rem;

  @media all and (min-width: 768px) {
    padding: 0 5rem;
    padding-bottom: 10rem;
  }

  @media all and (min-width: 1200px) {
    padding: 0 12rem;
    padding-bottom: 12rem;
  }
}

.SponsorForm {
  background-color: #191b25;
  height: 500px;
  border-radius: 2.5rem;
  margin-top: 1.6rem;

  @media all and (min-width: 768px) {
    margin-top: 2.4rem;
  }

  @media all and (min-width: 1200px) {
    height: 70vh;
  }
}

.SponsorClasses {
  padding: 0 5rem;
  padding-top: 1rem;
  margin: 0 -2rem;

  @media all and (min-width: 1200px) {
    padding: 0;
    padding-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.SponsorClass {
  padding: 0;
  margin-bottom: 6rem;
  flex: 1 0 calc(100% / 1 - 5rem);

  @media all and (min-width: 1200px) {
    flex: 1 0 calc(100% / 2 - 5rem);
    padding: 0 2rem;
    margin-bottom: 0;
  }

  @media all and (min-width: 1400px) {
    flex: 1;
  }

  h1 {
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 0.1rem;
    text-align: center;

    @media all and (min-width: 768px) {
      font-size: 56px;
      margin-bottom: 0.5rem;
    }

    @media all and (min-width: 1200px) {
      text-align: left;
    }
  }

  h2 {
    font-size: 17px;
    font-weight: 300;
    margin: 0.25rem 0;
    margin-left: 8rem;
    text-align: center;
    margin-right: 1rem;
    color: rgba(256, 256, 256, 0.47);

    @media all and (min-width: 768px) {
      margin-left: 18rem;
      font-size: 26px;
    }

    @media all and (min-width: 1200px) {
      margin-left: 0;
      text-align: right;
    }
  }

  ul {
    display: table;
    margin: 0 auto;
    padding-left: 0;
    padding-top: 1.5rem;

    @media all and (min-width: 1200px) {
      display: block;
      margin: 0;
      padding-left: 1.5rem;
    }
  }

  li {
    font-size: 14px;
    line-height: 28px;
    font-weight: normal;
    padding-bottom: 0.5rem;
    word-break: keep-all;

    @media all and (min-width: 768px) {
      font-size: 22px;
      line-height: 38px;
    }
  }
}

.SponsorSection5 {
  h1 {
    font-size: 24px;
    font-weight: bold;
    text-align: center;

    @media all and (min-width: 768px) {
      font-size: 56px;
    }
  }
}

.SponsorList {
  background-color: #191b25;
  border-radius: 2.5rem;
  margin-top: 2.4rem;
  padding: 0.25rem 0;

  @media all and (min-width: 768px) {
    padding: 1rem 0;
  }

  hr {
    margin: 2.8rem 8rem;
    border: 1px solid #282b39;

    @media all and (min-width: 768px) {
      margin: 2.8rem 10rem;
    }

    @media all and (min-width: 1200px) {
      margin: 2.8rem 18rem;
    }
  }
}

.SponsorListRow {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: white;
  padding : 0.5rem;
}

.SponsorListCardLarge {
  margin: 1rem;
  height: 150px;
  display: flex;
  cursor: pointer;

  flex: 1 1 calc(100% / 1 - 5rem);

  @media all and (min-width: 480px) {
    flex: 1 1 calc(100% / 2 - 5rem);
  }

  @media all and (min-width: 768px) {
    flex: 1 1 calc(100% / 3 - 5rem);
  }

  @media all and (min-width: 1200px) {
    flex: 1 1 calc(100% / 3 - 5rem);
  }

  @media all and (min-width: 1400px) {
    flex: 1 1 calc(100% / 4 - 5rem);
  }

  img {
    height: 100%;
    align-items: center;
    margin: auto;
  }
}
.SponsorListCardMedium {
  margin: 1rem;
  height: 60px;
  display: flex;
  cursor: pointer;

  flex: 1 1 calc(100% / 1 - 5rem);

  @media all and (min-width: 480px) {
    flex: 1 1 calc(100% / 2 - 5rem);
  }

  @media all and (min-width: 768px) {
    flex: 1 1 calc(100% / 3 - 5rem);
  }

  @media all and (min-width: 1200px) {
    flex: 1 1 calc(100% / 3 - 5rem);
  }


  img {
    height: 100%;
    border-radius: 20px;
    align-items: center;
    margin: auto;
  }
}

.SponsorListPersonRow {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0.8rem;
  margin: 0.7rem 0;
  flex-wrap: wrap;

  word-break: keep-all;

  @media all and (min-width: 480px) {
    margin: 0.7rem 2rem;
    padding: 0.4rem;
  }

  @media all and (min-width: 768px) {
    margin: 0.7rem 5rem;
  }

  @media all and (min-width: 1200px) {
    margin: 0.7rem 10rem;
  }
}

.SponsorListPerson {
  font-size: 12px;
  font-weight: 600;
  margin: 1rem;

  /* 한 줄당 6명의 이름이 배치됩니다 */
  flex: 1 0 calc(100% / 6 - 2rem);
  text-align: center;

  @media all and (min-width: 480px) {
    font-size: 20px;
  }

  @media all and (min-width: 768px) {
    font-size: 32px;
  }
}
